import CountriesService from '../../../../../services/api/countries'
import CrawlService from '../../../../../services/api/crawl'
import { getProducts } from '../../../../../redux/actions/auth'
export const C_START_FETCH = 'C_START_FETCH'
export const C_DONE_FETCH = 'C_DONE_FETCH'
export const C_SET_GIFTS = 'C_SET_GIFTS'
export const C_PAGE_NEXT = 'C_PAGE_NEXT'
export const C_PAGE_PREV = 'C_PAGE_PREV'
export const C_CHANGE_SCOPE = 'C_CHANGE_SCOPE'
export const C_CHANGE_TYPE = 'C_CHANGE_TYPE'
export const C_CLEANUP = 'C_CLEANUP'
export const C_SET_OWNER = 'C_SET_OWNER'
export const C_SET_CONTINUATION = 'C_SET_CONTINUATION'
export const C_SET_SUBLOAD = 'C_SET_SUBLOAD'
export const C_TOGGLE_MODAL = 'C_TOGGLE_MODAL'
export const C_SET_DOCUMENT = 'C_SET_DOCUMENT'
export const C_SET_CRAWL_NETWORKS = 'C_SET_CRAWL_NETWORKS'
export const C_DONE_SUBLOAD = 'C_DONE_SUBLOAD'
export const C_DONE_GROUPED = 'C_DONE_GROUPED'
export const C_ATTACHED_SET = 'C_ATTACHED_SET'
export const C_SET_OWNER_VALUE = 'C_SET_OWNER_VALUE'
export const C_SET_STORIES_COUNT = 'C_SET_STORIES_COUNT'
export const C_SET_POSTS_COUNT = 'C_SET_POSTS_COUNT'
export const C_SET_REELS_COUNT = 'C_SET_REELS_COUNT'
export const C_SET_TOTALPUBLI_COUNT = 'C_SET_TOTALPUBLI_COUNT'
export const C_SET_MEDIA_TAGS = 'C_SET_MEDIA_TAGS'
export const C_SET_MEDIA_TAG = 'C_SET_MEDIA_TAG'
export const C_TOGGLE_SELECT_TAG = 'C_TOGGLE_SELECT_TAG'
export const C_SET_CRAWL_SPECIALS = 'C_SET_CRAWL_SPECIALS'
export const C_SWITCH_ATTACH = 'C_SWITCH_ATTACH'
export const C_TOGGLE_FILTER_BY = 'C_TOGGLE_FILTER_BY'
export const C_TOGGLE_FILTER_DESC = 'C_TOGGLE_FILTER_DESC'
export const C_TOGGLE_IGNORE_FILTERS = 'C_TOGGLE_IGNORE_FILTERS'
export const C_SEARCH_USERNAME = 'C_SEARCH_USERNAME'
export const C_DATERANGE_SET = 'C_SEARCH_DATES'
export const C_TOGGLE_SEARCH_CONTAINS = 'C_TOGGLE_SEARCH_CONTAINS'
export const C_TZ_SET = 'C_TZ_SET'
export const C_SET_AREAS_SELECTION = 'C_SET_AREAS_SELECTION'
export const C_SET_AREAS = 'C_SET_AREAS'
export const C_SET_COUNTRIES = 'C_SET_COUNTRIES'
export const C_SET_COUNTRIES_SELECTION = 'C_SET_COUNTRIES_SELECTION'
export const C_SET_AREAS_DATA = 'C_SET_AREAS_DATA'
export const C_SET_PRODUCTS_SELECTION = 'C_SET_PRODUCTS_SELECTION'
export const C_TOGGLE_SEARCH_PROD_CONTAINS = 'C_TOGGLE_SEARCH_PROD_CONTAINS'

export const toggleModal = () => (dispatch, _getState) => {
  const state = _getState().crawlReducer
  dispatch({ type: C_TOGGLE_MODAL, value: !state.modalState })
}

export const setDocument = (doc) => (dispatch) => {
  dispatch({ type: C_SET_DOCUMENT, value: doc })
}

const mediaRoutine = (data, state) => {
  const mergedMedias = [...data.posts, ...data.stories]
  const mediasWithGifts = mergedMedias.map((med) => {
    return { ...med, gift: data.gifts?.find((g) => g.id === med.giftId) }
  })

  // here to group ?
  const sortedMedias = mediasWithGifts.sort(function (a, b) {
    if (state.continuationObject.orderBy === 'date') {
      return state.continuationObject.desc
        ? new Date(b.creationDate) - new Date(a.creationDate)
        : new Date(a.creationDate) - new Date(b.creationDate)
    }
    if (state.continuationObject.orderBy === 'likes') {
      return state.continuationObject.desc
        ? new Date(b.likesCount) - new Date(a.likesCount)
        : new Date(a.likesCount) - new Date(b.likesCount)
    }
  })

  // const sortedMedias = mediasWithGifts //.reverse()

  return sortedMedias
}

export const fetchProducts = () => (dispatch, _getState) => {
  const state = _getState().auth

  if (state.products.length === 0) {
    dispatch({ type: C_SET_SUBLOAD, value: true })
    dispatch(getProducts())
    // CountriesService.getAreasFull().then((response) => {
    //   const data = response.data.countries
    //   if (data) {
    //     dispatch({ type: C_SET_AREAS_DATA, value: data })
    //     dispatch({
    //       type: C_SET_AREAS,
    //       value: Array.from(new Set(data.map((d) => d.area)))
    //     })
    //     dispatch({ type: C_SET_COUNTRIES, value: data.map((d) => d.label) })
    //     dispatch({ type: C_SET_SUBLOAD, value: false })
    //   }
    // })
  }
}

export const fetchAreas = () => (dispatch, _getState) => {
  const state = _getState().crawlReducer

  if (state.areas.length === 0) {
    dispatch({ type: C_SET_SUBLOAD, value: true })

    CountriesService.getAreasFull().then((response) => {
      const data = response.data.countries
      if (data) {
        dispatch({ type: C_SET_AREAS_DATA, value: data })
        dispatch({
          type: C_SET_AREAS,
          value: Array.from(new Set(data.map((d) => d.area)))
        })
        dispatch({ type: C_SET_COUNTRIES, value: data.map((d) => d.label) })
        dispatch({ type: C_SET_SUBLOAD, value: false })
      }
    })
  }
}

export const fetchData = (email) => (dispatch, _getState) => {
  const state = _getState().crawlReducer

  dispatch({ type: C_SET_OWNER, value: email })

  dispatch({ type: C_START_FETCH })
  dispatch({ type: C_SET_SUBLOAD, value: true })

  CrawlService.continueGet({
    account: email,
    attachedOnly: state?.continuationObject?.attachedOnly,
    typed: state?.continuationObject?.typed,
    tags: state?.continuationObject?.tags,
    networks: state?.continuationObject?.networks,
    customGifts: state?.continuationObject?.customGifts,
    desc: state?.continuationObject?.desc,
    orderBy: state?.continuationObject?.orderBy,
    datesRange: state?.continuationObject?.datesRange,
    searchValue: state?.continuationObject?.searchValue,
    ignoreFilters: state?.continuationObject?.ignoreFilters,
    searchStartsWith: state?.continuationObject?.searchStartsWith,
    tzOffset: state?.continuationObject?.tzOffset,
    withNotMentioned: state?.continuationObject?.withNotMentioned,
    selectedAreas: state?.continuationObject?.selectedAreas,
    selectedCountries: state?.continuationObject?.selectedCountries,
    selectedProducts: state?.continuationObject?.selectedProducts,
    syncPeriod: state?.continuationObject?.syncPeriod,
    goneDetected: state?.continuationObject?.goneDetected
  }).then((response) => {
    const data = response.data
    if (data) {
      data.tokens.account = email
      dispatch({ type: C_SET_GIFTS, value: data.gifts })
      dispatch({ type: C_SET_CONTINUATION, value: data.tokens })
      dispatch({ type: C_SET_STORIES_COUNT, value: data.countStories })
      dispatch({ type: C_SET_POSTS_COUNT, value: data.countPosts })
      dispatch({ type: C_SET_REELS_COUNT, value: data.countReels })
      dispatch({
        type: C_SET_TOTALPUBLI_COUNT,
        value: data.countStories + data.countPosts
      })

      const sortedMedias = mediaRoutine(data, state)
      dispatch({ type: C_DONE_SUBLOAD, value: sortedMedias })

      dispatch({ type: C_DONE_FETCH, value: sortedMedias })
    }
  })
}

export const fetchMore = () => (dispatch, _getState) => {
  const state = _getState().crawlReducer
  dispatch({ type: C_SET_SUBLOAD, value: true })

  CrawlService.continueGet(state.continuationObject).then((response) => {
    const data = response.data

    if (data) {
      const sortedMedias = mediaRoutine(data, state)
      if (data.tokens.account === '') data.tokens.account = 'all'
      dispatch({ type: C_SET_GIFTS, value: data.gifts })
      dispatch({ type: C_SET_CONTINUATION, value: data.tokens })

      if (data.countStories > -1 && data.countPosts > -1) {
        dispatch({ type: C_SET_STORIES_COUNT, value: data.countStories })
        dispatch({ type: C_SET_POSTS_COUNT, value: data.countPosts })
        dispatch({
          type: C_SET_TOTALPUBLI_COUNT,
          value: data.countStories + data.countPosts
        })
      }
      if (data.countReels > -1) {
        dispatch({ type: C_SET_REELS_COUNT, value: data.countReels })
      }

      dispatch({ type: C_DONE_SUBLOAD, value: sortedMedias })
    }
  })
}

export const withNotMentioned = () => (dispatch, _getState) => {
  const state = _getState().crawlReducer
  const tokens = state.continuationObject

  tokens.withNotMentioned = !tokens.withNotMentioned
  tokens.token = null

  dispatch({ type: C_SET_CONTINUATION, value: tokens })
  dispatch({ type: C_DONE_FETCH, value: [] })
  dispatch(fetchMore())
}

export const withGones = () => (dispatch, _getState) => {
  const state = _getState().crawlReducer
  const tokens = state.continuationObject
  tokens.attachedOnly = true
  tokens.goneDetected = !tokens.goneDetected
  tokens.token = null

  dispatch({ type: C_SET_CONTINUATION, value: tokens })
  dispatch({ type: C_DONE_FETCH, value: [] })
  dispatch(fetchMore())
}

export const withSyncPeriod = () => (dispatch, _getState) => {
  const state = _getState().crawlReducer
  const tokens = state.continuationObject

  tokens.syncPeriod = tokens.syncPeriod > 0 ? 0 : 1
  tokens.token = null

  dispatch({ type: C_SET_CONTINUATION, value: tokens })
  dispatch({ type: C_DONE_FETCH, value: [] })
  dispatch(fetchMore())
}

export const attachedOnly = () => (dispatch, _getState) => {
  const state = _getState().crawlReducer
  const tokens = state.continuationObject

  if (tokens.attachedOnly) tokens.goneDetected = false
  tokens.attachedOnly = !tokens.attachedOnly

  dispatch({ type: C_SET_CRAWL_SPECIALS, value: [] })
  tokens.token = null

  dispatch({ type: C_SET_CONTINUATION, value: tokens })
  dispatch({ type: C_DONE_FETCH, value: [] })
  dispatch(fetchMore())
}

export const toggleSpecials = (special) => (dispatch, _getState) => {
  const state = _getState().crawlReducer
  let newArr = []
  if (state.continuationObject.customGifts.includes(special)) {
    state.continuationObject.customGifts.forEach((net) => {
      if (net !== special) newArr.push(net)
    })
  } else {
    if (!state.continuationObject.attachedOnly) {
      dispatch({ type: C_SWITCH_ATTACH, value: true })
    }
    newArr = state.continuationObject.customGifts
    newArr.push(special)
  }

  if (newArr.length === 0) {
    dispatch({ type: C_SWITCH_ATTACH, value: false })
  }

  dispatch({ type: C_SET_CRAWL_SPECIALS, value: newArr })
  dispatch(fetchData(state.owner))
}

export const toggleNetwork = (network) => (dispatch, _getState) => {
  const state = _getState().crawlReducer
  let newArr = []
  if (state.continuationObject.networks.includes(network)) {
    state.continuationObject.networks.forEach((net) => {
      if (net !== network) newArr.push(net)
    })
  } else {
    newArr = state.continuationObject.networks
    newArr.push(network)
  }

  dispatch({ type: C_SET_CRAWL_NETWORKS, value: newArr })
  dispatch(fetchData(state.owner))
}

export const changeViewType = (value) => (dispatch, _getState) => {
  dispatch({ type: C_CHANGE_TYPE, value })
  const state = _getState().crawlReducer
  const tokens = state.continuationObject

  tokens.typed = value
  tokens.token = null

  dispatch({ type: C_SET_CONTINUATION, value: tokens })
  dispatch({ type: C_SET_SUBLOAD, value: true })
  dispatch({ type: C_DONE_FETCH, value: [] })
  dispatch(fetchMore())
}

export const setOwnerValue = (val) => (dispatch) => {
  dispatch({ type: C_SET_OWNER_VALUE, value: val })
}

export const setMediaTags = (val) => (dispatch) => {
  dispatch({ type: C_SET_MEDIA_TAGS, value: val })
}

export const createTagEvent = (val) => (dispatch) => {
  dispatch({ type: C_SET_MEDIA_TAG, value: val })
}

export const toggleSelectedTag = (value) => (dispatch, _getState) => {
  const state = _getState().crawlReducer
  const tags = Object.assign([], state.continuationObject.tags)

  if (tags.includes(value)) {
    dispatch({
      type: C_TOGGLE_SELECT_TAG,
      value: tags.filter((t) => t !== value)
    })
  } else {
    tags.push(value)
    dispatch({ type: C_TOGGLE_SELECT_TAG, value: tags })
  }
  dispatch(fetchData(state.owner))
}
// export const changeScope = (value) => (dispatch, _getState) => {
//   const state = _getState().crawlReducer
//   dispatch({ type: C_SET_OWNER, value })
//   dispatch({ type: C_START_FETCH })

//   CrawlService.continueGet({
//     account: value,
//     attachedOnly: state?.continuationObject?.attachedOnly,
//     typed: state?.continuationObject?.typed
//   }).then((response) => {
//     const data = response.data
//     if (data) {
//       const sortedMedias = mediaRoutine(data, state)

//       data.tokens.account = value
//       dispatch({ type: C_SET_GIFTS, value: data.gifts })
//       dispatch({ type: C_SET_CONTINUATION, value: data.tokens })

//       dispatch({ type: C_DONE_FETCH, value: sortedMedias })
//       dispatch({ type: C_CHANGE_SCOPE, value })
//     }
//   })
// }

export const toggleDesc = () => (dispatch, _getState) => {
  const state = _getState().crawlReducer

  dispatch({ type: C_TOGGLE_FILTER_DESC })
  dispatch(fetchData(state.owner))
}

export const toggleOrderBy = (value) => (dispatch, _getState) => {
  const state = _getState().crawlReducer

  //dispatch({ type: C_TOGGLE_FILTER_DESC })
  if (state.continuationObject.orderBy === value) {
    dispatch({ type: C_TOGGLE_FILTER_DESC })
  } else {
    dispatch({ type: C_TOGGLE_FILTER_BY, value })
  }
  dispatch(fetchData(state.owner))
}

export const toggleIgnoreFilters = () => (dispatch, _getState) => {
  dispatch({ type: C_TOGGLE_IGNORE_FILTERS })
}

let goTimer
const goTimerSet = (timer) => {
  goTimer = timer
}

export const searchUsername = (value) => (dispatch, _getState) => {
  dispatch({ type: C_SEARCH_USERNAME, value })
  const state = _getState().crawlReducer
  if (value === '') {
    dispatch(fetchData(state.owner))
    return null
  }
  clearTimeout(goTimer)

  goTimerSet(
    setTimeout(() => {
      dispatch(fetchData(state.owner))
    }, 800)
  )
}

export const dateRangeSet = (value) => (dispatch, _getState) => {
  console.log(value)
  const state = _getState().crawlReducer

  dispatch({ type: C_DATERANGE_SET, value })

  dispatch({ type: C_TZ_SET, value: value[0]?.getTimezoneOffset() })

  dispatch(fetchData(state.owner))
}

export const clearUsername = () => (dispatch, _getState) => {
  const state = _getState().crawlReducer

  if (state.continuationObject.searchValue !== '') {
    dispatch(searchUsername(''))
  }
}

export const clearDates = () => (dispatch, _getState) => {
  const state = _getState().crawlReducer

  if (state.continuationObject.datesRange?.length) {
    dispatch(dateRangeSet([]))
  }
}
export const clearProducts = () => (dispatch) => {
  dispatch({
    type: C_SET_PRODUCTS_SELECTION,
    value: []
  })
}

export const toggleSearchProductsContains = () => (dispatch, _getState) => {
  const state = _getState().crawlReducer
  dispatch({ type: C_TOGGLE_SEARCH_PROD_CONTAINS })

  if (state.continuationObject.selectedProducts?.length > 0) {
    dispatch(fetchData(state.owner))
  }
}

export const toggleSearchContains = () => (dispatch, _getState) => {
  const state = _getState().crawlReducer
  dispatch({ type: C_TOGGLE_SEARCH_CONTAINS })

  if (state.continuationObject.searchValue !== '') {
    dispatch(fetchData(state.owner))
  }
}

export const handleAreaSelection = (selection) => (dispatch, _getState) => {
  const state = _getState().crawlReducer

  dispatch({
    type: C_SET_AREAS_SELECTION,
    value: selection === undefined ? [] : selection
  })

  const flatSelection = selection.map((d) => d.label)
  const alloweds = state.areaCountries.filter((d) =>
    flatSelection.includes(d.area)
  )

  dispatch({
    type: C_SET_COUNTRIES,
    value:
      selection === undefined || selection?.length === 0
        ? state.areaCountries.map((d) => d.label)
        : alloweds.map((d) => d.label)
  })

  const tokens = state.continuationObject

  tokens.attachedOnly = !tokens.attachedOnly
  dispatch({ type: C_SET_CRAWL_SPECIALS, value: [] })
  tokens.token = null

  dispatch({ type: C_SET_CONTINUATION, value: tokens })
  dispatch({ type: C_DONE_FETCH, value: [] })
  dispatch(fetchMore())
}

export const handleCountrySelection = (selection) => (dispatch, _getState) => {
  const state = _getState().crawlReducer

  dispatch({
    type: C_SET_COUNTRIES_SELECTION,
    value: selection === undefined ? [] : selection
  })

  const tokens = state.continuationObject

  tokens.attachedOnly = !tokens.attachedOnly
  dispatch({ type: C_SET_CRAWL_SPECIALS, value: [] })
  tokens.token = null

  dispatch({ type: C_SET_CONTINUATION, value: tokens })
  dispatch({ type: C_DONE_FETCH, value: [] })
  dispatch(fetchMore())
}

export const handleProductsSelection = (selection) => (dispatch, _getState) => {
  const state = _getState().crawlReducer

  dispatch({
    type: C_SET_PRODUCTS_SELECTION,
    value: selection === undefined ? [] : selection
  })

  const tokens = state.continuationObject

  tokens.attachedOnly = !tokens.attachedOnly
  dispatch({ type: C_SET_CRAWL_SPECIALS, value: [] })
  tokens.token = null

  dispatch({ type: C_SET_CONTINUATION, value: tokens })
  dispatch({ type: C_DONE_FETCH, value: [] })

  dispatch(fetchMore())
}
