// **  Initial State
const initialState = {
  userData: {},
  accounts: [],
  countries: [],
  objectives: [],
  products: []
}

const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'LOGIN':
      return {
        ...state,
        userData: action.data,
        [action.config.storageTokenKeyName]:
          action[action.config.storageTokenKeyName],
        [action.config.storageRefreshTokenKeyName]:
          action[action.config.storageRefreshTokenKeyName]
      }
    case 'LOGOUT': {
      const obj = { ...action }
      delete obj.type
      return { ...state, userData: {}, ...obj }
    }
    case 'config/orgAccs': {
      return { ...state, accounts: action.value }
    }
    case 'config/countries': {
      return { ...state, countries: action.value }
    }
    case 'config/objectives': {
      return { ...state, objectives: action.value }
    }
    case 'config/products': {
      return { ...state, products: action.value }
    }
    case 'UPDATE_PNS_LIGHT_MODE': {
      const { email, pnSLightMode } = action.payload

      return {
        ...state,
        accounts: state.accounts.map((account) =>
          account.email === email ? { ...account, pnSLightMode } : account
        )
      }
    }
    case 'UPDATE_PNS_GONES': {
      const { email, pnSGones } = action.payload

      return {
        ...state,
        accounts: state.accounts.map((account) =>
          account.email === email ? { ...account, pnSGones } : account
        )
      }
    }
    case 'UPDATE_PNS_UPDATED': {
      const { email, pnSUpdated } = action.payload

      return {
        ...state,
        accounts: state.accounts.map((account) =>
          account.email === email ? { ...account, pnSUpdated } : account
        )
      }
    }
    default:
      return state
  }
}

export default authReducer
