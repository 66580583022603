export const networksList = [
  'instagram',
  'tiktok',
  'youtube',
  'wechat',
  'littleredbook',
  'weibo',
  'bilibili',
  'naver',
  'douyin'
]

export const networksListObject = [
  { value: 'instagram', label: 'Instagram' },
  { value: 'tiktok', label: 'TikTok' },
  { value: 'youtube', label: 'YouTube' },
  { value: 'wechat', label: 'WeChat' },
  { value: 'littleredbook', label: 'LittleRedBook' },
  { value: 'weibo', label: 'Weibo' },
  { value: 'bilibili', label: 'Bilibili' },
  { value: 'naver', label: 'Naver' },
  { value: 'douyin', label: 'Douyin' }
]
