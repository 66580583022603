import {
  C_ATTACHED_SET,
  C_CHANGE_SCOPE,
  C_CHANGE_TYPE,
  C_CLEANUP,
  C_DATERANGE_SET,
  C_DONE_FETCH,
  C_DONE_GROUPED,
  C_DONE_SUBLOAD,
  C_PAGE_NEXT,
  C_PAGE_PREV,
  C_SEARCH_USERNAME,
  C_SET_AREAS,
  C_SET_AREAS_DATA,
  C_SET_AREAS_SELECTION,
  C_SET_CONTINUATION,
  C_SET_COUNTRIES,
  C_SET_COUNTRIES_SELECTION,
  C_SET_CRAWL_NETWORKS,
  C_SET_CRAWL_SPECIALS,
  C_SET_DOCUMENT,
  C_SET_GIFTS,
  C_SET_MEDIA_TAG,
  C_SET_MEDIA_TAGS,
  C_SET_OWNER,
  C_SET_OWNER_VALUE,
  C_SET_POSTS_COUNT,
  C_SET_PRODUCTS_SELECTION,
  C_SET_REELS_COUNT,
  C_SET_STORIES_COUNT,
  C_SET_SUBLOAD,
  C_SET_TOTALPUBLI_COUNT,
  C_START_FETCH,
  C_SWITCH_ATTACH,
  C_TOGGLE_FILTER_BY,
  C_TOGGLE_FILTER_DESC,
  C_TOGGLE_IGNORE_FILTERS,
  C_TOGGLE_MODAL,
  C_TOGGLE_SEARCH_CONTAINS,
  C_TOGGLE_SEARCH_PROD_CONTAINS,
  C_TOGGLE_SELECT_TAG,
  C_TZ_SET
} from './actions'

import { networksList } from '../../../../extensions/networks'

const initialState = () => {
  const initialState = {
    data: [],
    countStories: 0,
    countPosts: 0,
    countReels: 0,
    totalPublications: 0,
    gifts: [],
    mediaTags: [],
    selectedNetworks: Object.assign([], networksList),
    page: 1,
    owner: '',
    ownerSelect: {},
    type: '',
    fetching: true,
    subLoading: false,
    document: null,
    modalState: false,
    areas: [],
    countries: [],
    areaCountries: [],
    continuationObject: {
      tzOffset: 0,
      datesRange: [],
      searchValue: '',
      searchStartsWith: true,
      selectedProductsFilterIsAnd: false,
      ignoreFilters: false,
      orderBy: 'date',
      desc: true,
      customGifts: [],
      networks: [],
      tags: [],
      account: null,
      token: null,
      attachedOnly: false,
      withNotMentioned: false,
      selectedAreas: [],
      selectedCountries: [],
      selectedProducts: [],
      syncPeriod: 0,
      goneDetected: false
    }
  }

  return initialState
}

const aggregategifts = (state, gifts) => {
  const existingArray = state.gifts
  gifts.forEach((newGift) => {
    const found = existingArray.find((g) => g.id === newGift.id)
    if (!found) {
      existingArray.push(newGift)
    }
  })

  return existingArray
}

const pushMediaTag = (tag, arr) => {
  const newArr = Object.assign([], arr)
  newArr.push(tag)
  return newArr
}

const crawlReducer = (state = initialState(), action) => {
  switch (action.type) {
    case C_TOGGLE_SEARCH_CONTAINS: {
      return {
        ...state,
        continuationObject: {
          ...state.continuationObject,
          searchStartsWith: !state.continuationObject.searchStartsWith
        }
      }
    }
    case C_TOGGLE_SEARCH_PROD_CONTAINS: {
      return {
        ...state,
        continuationObject: {
          ...state.continuationObject,
          selectedProductsFilterIsAnd:
            !state.continuationObject.selectedProductsFilterIsAnd
        }
      }
    }
    case C_SET_AREAS: {
      return {
        ...state,
        areas: action.value
      }
    }
    case C_SET_AREAS_SELECTION: {
      return {
        ...state,
        continuationObject: {
          ...state.continuationObject,
          selectedAreas: action.value
        }
      }
    }
    case C_SET_AREAS_DATA: {
      return {
        ...state,
        areaCountries: action.value
      }
    }
    case C_SET_COUNTRIES: {
      return {
        ...state,
        countries: action.value
      }
    }
    case C_SET_COUNTRIES_SELECTION: {
      return {
        ...state,
        continuationObject: {
          ...state.continuationObject,
          selectedCountries: action.value
        }
      }
    }
    case C_TZ_SET: {
      return {
        ...state,
        continuationObject: {
          ...state.continuationObject,
          tzOffset: action.value
        }
      }
    }
    case C_DATERANGE_SET: {
      return {
        ...state,
        continuationObject: {
          ...state.continuationObject,
          datesRange: action.value
        }
      }
    }
    case C_SEARCH_USERNAME: {
      return {
        ...state,
        continuationObject: {
          ...state.continuationObject,
          searchValue: action.value
        }
      }
    }
    case C_TOGGLE_IGNORE_FILTERS: {
      return {
        ...state,
        continuationObject: {
          ...state.continuationObject,
          ignoreFilters: !state.continuationObject.ignoreFilters
        }
      }
    }
    case C_TOGGLE_FILTER_BY: {
      return {
        ...state,
        continuationObject: {
          ...state.continuationObject,
          orderBy: action.value
        }
      }
    }
    case C_TOGGLE_FILTER_DESC: {
      return {
        ...state,
        continuationObject: {
          ...state.continuationObject,
          desc: !state.continuationObject.desc
        }
      }
    }
    case C_SWITCH_ATTACH: {
      return {
        ...state,
        continuationObject: {
          ...state.continuationObject,
          attachedOnly: action.value
        }
      }
    }

    case C_TOGGLE_SELECT_TAG: {
      return {
        ...state,
        continuationObject: { ...state.continuationObject, tags: action.value }
      }
    }
    case C_SET_MEDIA_TAG: {
      return {
        ...state,
        mediaTags: pushMediaTag(action.value, state.mediaTags)
      }
    }
    case C_SET_MEDIA_TAGS: {
      return { ...state, mediaTags: action.value }
    }
    case C_START_FETCH: {
      return { ...state, fetching: true }
    }
    case C_SET_STORIES_COUNT: {
      return { ...state, countStories: action.value }
    }
    case C_SET_POSTS_COUNT: {
      return { ...state, countPosts: action.value }
    }
    case C_SET_REELS_COUNT: {
      return { ...state, countReels: action.value }
    }
    case C_SET_TOTALPUBLI_COUNT: {
      return { ...state, totalPublications: action.value }
    }
    case C_SET_CONTINUATION: {
      return {
        ...state,
        continuationObject: {
          ...state.continuationObject,
          account: action.value.account,
          token: action.value.token
        }
      }
    }
    case C_SET_OWNER_VALUE: {
      return { ...state, ownerSelect: action.value }
    }
    case C_SET_OWNER: {
      return { ...state, owner: action.value }
    }
    case C_DONE_FETCH: {
      return { ...state, data: action.value, fetching: false }
    }
    case C_SET_SUBLOAD: {
      return {
        ...state,
        subLoading: action.value
      }
    }
    case C_DONE_SUBLOAD: {
      return {
        ...state,
        data: [...state.data, ...action.value],
        subLoading: false
      }
    }
    case C_CHANGE_SCOPE: {
      return {
        ...state,
        owner: action.value,
        continuationObject: {
          ...state.continuationObject,
          account: action.value
        }
      }
    }
    case C_CHANGE_TYPE: {
      return { ...state, type: action.value }
    }
    case C_SET_GIFTS: {
      return { ...state, gifts: aggregategifts(state, action.value) }
    }
    case C_SET_PRODUCTS_SELECTION: {
      return {
        ...state,
        continuationObject: {
          ...state.continuationObject,
          selectedProducts: action.value
        }
      }
    }
    case C_SET_CRAWL_NETWORKS: {
      return {
        ...state,
        continuationObject: {
          ...state.continuationObject,
          networks: action.value
        }
      }
    }
    case C_SET_CRAWL_SPECIALS: {
      return {
        ...state,
        continuationObject: {
          ...state.continuationObject,
          customGifts: action.value
        }
      }
    }
    case C_CLEANUP: {
      return initialState()
    }
    case C_TOGGLE_MODAL: {
      return { ...state, modalState: action.value }
    }
    case C_SET_DOCUMENT: {
      return { ...state, document: action.value }
    }
    default:
      return state
  }
}

export default crawlReducer
