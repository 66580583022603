import {
  ED_DELSET_NETWORK,
  ED_GPT_LOADING,
  ED_GPT_MODAL,
  ED_LOADING,
  ED_LOAD_ACCOUNTS,
  ED_LOAD_COUNTRIES,
  ED_LOAD_GPT,
  ED_LOAD_USER,
  ED_NET_ADD_STATE,
  ED_NET_MODAL_STATE,
  ED_NET_MODAL_USERNAME,
  ED_PUSH_NETWORK,
  ED_SET_ACCMANAGER,
  ED_SET_CREATE,
  ED_SET_MANAGER,
  ED_SET_NETWORK,
  ED_UPDATE_AI_TEXT,
  ED_UPDATE_TEXT,
  INFO_LOADING,
  LOAD_ERROR,
  OPERATION_CHANGE
} from './actions'

const initialState = {
  loadingInfo: '',
  loadError: false,
  loading: true,
  user: {},
  network: {},
  countries: [],
  accounts: [],
  isManager: false,
  isCreate: false,
  errors: [],
  networkModalState: false,
  addNetwork: {},
  username: '',
  gpt: {
    modalOpen: false,
    loading: true
  }
}

const pushNetworkIntoUser = (state, networkToPush) => {
  const user = state.user

  const exists = user.networks.find(
    (n) => n.networkName === networkToPush.networkName
  )
  if (exists) {
    const newUser = Object.assign({}, user)
    const networks = []

    for (let index = 0; index < user.networks.length; index++) {
      const network = user.networks[index]
      if (network.networkName === networkToPush.networkName) {
        if (network.networkName === 'youtube') {
          network.userIdInNetwork = networkToPush.userIdInNetwork
        } else {
          network.usernameInNetwork = networkToPush.usernameInNetwork
        }
      }
      networks.push(network)
    }

    newUser.networks = networks
    return newUser
  } else {
    user.networks.push(networkToPush)
    return user
  }
}

const editUserReducer = (state = initialState, action) => {
  switch (action.type) {
    case ED_LOADING:
      return { ...state, loading: action.value }
    case ED_GPT_LOADING:
      return { ...state, gpt: { ...state.gpt, loading: action.value } }
    case ED_GPT_MODAL:
      return { ...state, gpt: { ...state.gpt, modalOpen: action.value } }
    case ED_LOAD_GPT:
      return { ...state, gpt: { ...state.gpt, response: action.value } }
    case ED_SET_NETWORK:
      return { ...state, network: action.value }
    case ED_LOAD_USER:
      return { ...state, user: action.value }
    case ED_LOAD_COUNTRIES:
      return { ...state, countries: action.value }
    case ED_LOAD_ACCOUNTS:
      return { ...state, accounts: action.value }
    case ED_SET_MANAGER:
      return { ...state, isManager: action.value }
    case ED_SET_CREATE:
      return { ...state, isCreate: action.value }
    // GENERAL TAB
    case ED_UPDATE_TEXT:
      return { ...state, user: { ...state.user, [action.prop]: action.value } }
    case ED_UPDATE_AI_TEXT:
      return {
        ...state,
        gpt: {
          ...state.gpt,
          response: {
            ...state.gpt.response,
            computed: {
              ...state.gpt.response.computed,
              [action.prop]: action.value
            }
          }
        }
      }
    case ED_SET_ACCMANAGER:
      return { ...state, user: { ...state.user, accountManager: action.value } }
    // NETWORK MODAL
    case ED_NET_MODAL_STATE:
      return { ...state, networkModalState: action.value }
    case ED_NET_ADD_STATE:
      return { ...state, addNetwork: action.value }
    case ED_PUSH_NETWORK:
      return { ...state, user: pushNetworkIntoUser(state, action.value) }
    case ED_NET_MODAL_USERNAME:
      return { ...state, username: action.value }
    case OPERATION_CHANGE:
      return { ...state, user: { ...state.user, operations: action.value } }
    case ED_DELSET_NETWORK: {
      return { ...state, user: { ...state.user, networks: action.value } }
    }
    case INFO_LOADING: {
      return { ...state, loadingInfo: action.value }
    }
    case LOAD_ERROR: {
      return { ...state, loadError: action.value }
    }
    default:
      return state
  }
}

export default editUserReducer
