import request from '../../shared/lib/request'
const rootApiUrl = process.env.REACT_APP_API_URL

function search(val) {
  return request({
    url: `${rootApiUrl}/store/${val}/search`,
    method: 'GET'
  })
}

function get() {
  return request({
    url: `${rootApiUrl}/store`,
    method: 'GET'
  })
}

function getById(id) {
  return request({
    url: `${rootApiUrl}/store/${id}`,
    method: 'GET'
  })
}

function post(store) {
  return request({
    url: `${rootApiUrl}/store`,
    method: 'POST',
    data: store
  })
}

function del(id) {
  return request({
    url: `${rootApiUrl}/store/${id}`,
    method: 'DELETE'
  })
}

const StoresService = {
  search,
  get,
  getById,
  post,
  del
}

export default StoresService
