import request from '../../shared/lib/request'
const rootApiUrl = process.env.REACT_APP_API_URL

function del(id) {
  return request({
    url: `${rootApiUrl}/gifts/id/${id}/delete`,
    method: 'DELETE'
  })
}

function returned(id) {
  return request({
    url: `${rootApiUrl}/gifts/id/${id}/return`,
    method: 'DELETE'
  })
}

function get(args = '') {
  return request({
    url: `${rootApiUrl}/gifts/users/${args}`,
    method: 'GET'
  })
}

function getByUserInternalId(args = '') {
  return request({
    url: `${rootApiUrl}/gifts/users/internalId/${args}`,
    method: 'GET'
  })
}

function getById(id) {
  return request({
    url: `${rootApiUrl}/gifts/id/${id}`,
    method: 'GET'
  })
}

function medias(id) {
  return request({
    url: `${rootApiUrl}/gifts/medias/${id}`,
    method: 'GET'
  })
}

function getAll() {
  return request({
    url: `${rootApiUrl}/gifts`,
    method: 'GET'
  })
}

function post(body) {
  return request({
    url: `${rootApiUrl}/gifts/`,
    method: 'POST',
    data: body
  })
}

function patch(giftId, erpId) {
  return request({
    url: `${rootApiUrl}/gifts/${giftId}/patch/${erpId}`,
    method: 'GET'
  })
}
function patchDate(giftId, date) {
  const epoch = date.length ? date[0].getTime() : date.getTime()
  return request({
    url: `${rootApiUrl}/gifts/${giftId}/patch/date/${epoch}`,
    method: 'GET'
  })
}
function patchAll(ids) {
  return request({
    url: `${rootApiUrl}/gifts/mark/extracted`,
    method: 'POST',
    data: ids
  })
}

function toggle(ids, property) {
  return request({
    url: `${rootApiUrl}/gifts/toggle/${property}`,
    method: 'POST',
    data: ids
  })
}

function search(body) {
  return request({
    url: `${rootApiUrl}/gifts/search`,
    method: 'POST',
    data: body
  })
}
function getReport() {
  return request({
    url: `${rootApiUrl}/gifts/mini/report`,
    method: 'GET'
  })
}

function count() {
  return request({
    url: `${rootApiUrl}/gifts/count`,
    method: 'GET'
  })
}

function getReportPost(date) {
  return request({
    url: `${rootApiUrl}/gifts/mini/report/date`,
    method: 'POST',
    data: { negotiatedAfter: date }
  })
}

function getUniqueId() {
  return request({
    url: `${rootApiUrl}/gifts/unique/identifier`,
    method: 'GET'
  })
}

const GiftsService = {
  count,
  del,
  returned,
  medias,
  toggle,
  get,
  getByUserInternalId,
  getById,
  getReport,
  getReportPost,
  patch,
  patchDate,
  search,
  getAll,
  patchAll,
  getUniqueId,
  post //, update, delete, etc. ...
}

export default GiftsService
