/* eslint-disable no-use-before-define */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

import CrawlService from '../../../../../services/api/crawl'
import FileService from '../../../../../services/api/file'
import GiftsService from '../../../../../services/api/gifts'
import PostService from '../../../../../services/api/post'
import ScheduleService from '../../../../../services/api/schedule'
import UsersService from '../../../../../services/api/users'

export const init = createAsyncThunk(
  'viewInfluencer/init',
  async (param, { rejectWithValue }) => {
    const urlId = param.id

    const user = await UsersService.get(urlId, null).catch((e) => {
      throw rejectWithValue(e.data)
    })

    const network =
      user.data.networks.filter((n) => n.isSourceNetwork)[0]?.networkName ??
      user.data.networks[0] ??
      'instagram'

    const id = user.data.username
    const internalUserId = user.data.internalId
    const gifts = await GiftsService.getByUserInternalId(user.data.internalId)
    const medias = await CrawlService.attached(user.data.internalId)
    const comments = await PostService.getCommentsFor(id)
    const meetings = await ScheduleService.getByInfluencer(id)
    const files = await FileService.getFilesFor(user.data.internalId)

    const mergedMedias = [...medias.data.posts, ...medias.data.stories]
    const mediasNetworks = medias.data.networks
    const mediasMostLiked = medias.data.mostLikes
    const mediasNbPosts = medias.data.nbPosts
    const mediasNbStories = medias.data.nbStories

    const mediasWithGifts = mergedMedias.map((med) => {
      return { ...med, gift: gifts?.data.find((g) => g.id === med.giftId) }
    })

    return {
      user: user.data,
      gifts: gifts.data,
      medias: mediasWithGifts,
      mediasNetworks,
      mediasMostLiked,
      mediasNbPosts,
      mediasNbStories,
      comments: comments.data,
      meetings: meetings.data,
      files: files.data,
      network,
      internalUserId
    }
  }
)

// Helper function to apply filters
const applyFilters = (medias, typeFilter, networkFilter, sortFilter) => {
  const filtered = medias
    .filter(
      (m) =>
        typeFilter === 'All Types' || m.sourceType === typeFilter.toLowerCase()
    )
    .filter(
      (m) => networkFilter === 'All Networks' || m.source === networkFilter
    )
    .sort((a, b) => {
      switch (sortFilter) {
        case 'oldestFirst':
          return new Date(a.creationDate) - new Date(b.creationDate)
        case 'mostLikedFirst':
          return b.likesCount - a.likesCount
        case 'mostRecent':
          return new Date(b.creationDate) - new Date(a.creationDate)
        default:
          return 0
      }
    })

  const nbPosts = filtered.filter((m) => m.sourceType === 'post').length
  const nbStories = filtered.filter((m) => m.sourceType === 'story').length

  // return filtered
  return { filtered, nbPosts, nbStories }
}

// Filter by media type
export const typeFilterChange = createAsyncThunk(
  'viewInfluencer/typeFilterChange',
  async (value, { getState }) => {
    const { medias, currentNetworkFilter, currentSortFilter } = getState().view
    return new Promise((resolve) =>
      setTimeout(() => {
        resolve(
          applyFilters(medias, value, currentNetworkFilter, currentSortFilter)
        )
      }, 0)
    )
  }
)

// Filter by network
export const networkFilterChange = createAsyncThunk(
  'viewInfluencer/networkFilterChange',
  async (value, { getState }) => {
    const { medias, currentTypeFilter, currentSortFilter } = getState().view

    return new Promise((resolve) =>
      setTimeout(() => {
        resolve(
          applyFilters(medias, currentTypeFilter, value, currentSortFilter)
        )
      }, 0)
    )
  }
)

// Sort the filtered media
export const mediaFilterChange = createAsyncThunk(
  'viewInfluencer/mediaFilterChange',
  async (value, { getState }) => {
    const { medias, currentTypeFilter, currentNetworkFilter } = getState().view

    return new Promise((resolve) =>
      setTimeout(() => {
        resolve(
          applyFilters(medias, currentTypeFilter, currentNetworkFilter, value)
        )
      }, 0)
    )
  }
)

// Other asynchronous operations
export const fetchComments = createAsyncThunk(
  'viewInfluencer/fetchComments',
  async (id) => {
    const comments = await PostService.getCommentsFor(id)
    return comments.data
  }
)

export const addManualMedia = createAsyncThunk(
  'viewInfluencer/addManualMedia',
  async (media, { getState }) => {
    const store = getState().view
    const assignedGift = store.gifted?.find((g) => g.id === media.giftId)
    if (assignedGift) {
      media = Object.assign({}, media, { gift: assignedGift })
    }

    return [media, ...store.medias]
  }
)

export const refreshFiles = createAsyncThunk(
  'viewInfluencer/refreshFiles',
  async (id) => {
    const files = await FileService.getFilesFor(id)
    return files.data
  }
)

export const viewInfluencerSlice = createSlice({
  name: 'viewInfluencer',
  initialState: {
    id: '',
    data: {},
    gifted: [],
    files: [],
    networks: [],
    meetings: [],
    hasLoaded: false,
    mediaLoading: true,
    medias: null,
    filteredMedias: null,
    mediasNetworks: [],
    mediasMostLiked: 0,
    mediasNbPosts: 0,
    mediasNbStories: 0,
    currentTypeFilter: 'All Types',
    currentNetworkFilter: 'All Networks',
    currentSortFilter: 'mostRecent',
    disableQ: false,
    accountManager: '',
    lock: false,
    openComModal: false,
    openMeetModal: false,
    openGiveawayModal: false,
    comments: [],
    network: 'instagram',
    activeHeaderTab: '0',
    internalUserId: ''
  },
  reducers: {
    setMediaLoading: (state, action) => {
      state.mediaLoading = action.payload
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(init.pending, (state) => {
        state.hasLoaded = false
      })
      .addCase(init.rejected, () => {
        window.location = '/forbidden'
      })
      .addCase(init.fulfilled, (state, action) => {
        state.network = action.payload.network
        state.data = action.payload.user
        state.gifted = action.payload.gifts
        state.medias = action.payload.medias
        state.mediasNetworks = [
          'All Networks',
          ...action.payload.mediasNetworks
        ]
        state.mediasMostLiked = action.payload.mediasMostLiked
        state.mediasNbPosts = action.payload.mediasNbPosts
        state.mediasNbStories = action.payload.mediasNbStories
        state.comments = action.payload.comments
        state.accountManager = action.payload.user.accountManager
        state.networks = action.payload.user.networks
        state.meetings = action.payload.meetings
        state.files = action.payload.files
        state.id = action.payload.user.username
        state.internalUserId = action.payload.internalUserId
        state.hasLoaded = true
        state.mediaLoading = false
        const { filtered, nbPosts, nbStories } = applyFilters(
          action.payload.medias,
          state.currentTypeFilter,
          state.currentNetworkFilter,
          state.currentSortFilter
        )
        state.filteredMedias = filtered
      })
      .addCase(addManualMedia.fulfilled, (state, action) => {
        state.medias = action.payload
        const { filtered, nbPosts, nbStories } = applyFilters(
          action.payload,
          state.currentTypeFilter,
          state.currentNetworkFilter,
          state.currentSortFilter
        )
        state.filteredMedias = filtered
      })
      .addCase(fetchComments.fulfilled, (state, action) => {
        state.comments = action.payload
      })
      .addCase(refreshFiles.fulfilled, (state, action) => {
        state.files = action.payload
      })
      .addCase(typeFilterChange.pending, (state) => {
        state.mediaLoading = true
      })
      .addCase(typeFilterChange.fulfilled, (state, action) => {
        state.filteredMedias = action.payload.filtered
        state.currentTypeFilter = action.meta.arg

        state.mediaLoading = false
      })
      .addCase(networkFilterChange.pending, (state) => {
        state.mediaLoading = true
      })
      .addCase(networkFilterChange.fulfilled, (state, action) => {
        state.filteredMedias = action.payload.filtered
        state.currentNetworkFilter = action.meta.arg
        state.mediasNbPosts = action.payload.nbPosts
        state.mediasNbStories = action.payload.nbStories
        state.mediaLoading = false
      })
      .addCase(mediaFilterChange.pending, (state) => {
        state.mediaLoading = true
      })
      .addCase(mediaFilterChange.fulfilled, (state, action) => {
        state.filteredMedias = action.payload.filtered
        state.currentSortFilter = action.meta.arg
        state.mediaLoading = false
      })
  }
})

export const { setMediaLoading } = viewInfluencerSlice.actions
export default viewInfluencerSlice.reducer
