/* eslint-disable no-use-before-define */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

import StoresService from '../../../../../../services/api/stores'

export const switchModal = createAsyncThunk(
  'storeStore/switchModal',
  async (params, { getState }) => {
    return true
  }
)

export const getStores = createAsyncThunk(
  'storeStore/getStores',
  async (params, { getState }) => {
    const request = await StoresService.get()

    return request.data
  }
)

export const delStore = createAsyncThunk(
  'storeStore/delStore',
  async (params, { getState }) => {
    await StoresService.del(params)
    const stores = await StoresService.get()

    return stores
  }
)

export const saveStore = createAsyncThunk(
  'storeStore/saveStore',
  async (params, { getState }) => {
    const request = await StoresService.post(params)
    const stores = await StoresService.get()

    return { request, stores }
  }
)

export const selectStore = (store) => (dispatch) => {
  dispatch(setSelectedStore(store))
  dispatch(switchModal(true)) // Open the modal
}

export const storeStore = createSlice({
  name: 'storeStore',
  initialState: {
    createModal: false,
    count: 0,
    selectedStore: null, // Store the selected store
    stores: [],
    loading: false
  },
  reducers: {
    setSelectedStore: (state, action) => {
      const store = state.stores.filter((b) => b.id === action.payload) // Set the selected store

      state.selectedStore = store[0]
    },
    clearSelectedStore: (state) => {
      state.selectedStore = null // Clear selected store when modal closes
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(switchModal.fulfilled, (state) => {
        state.createModal = !state.createModal
      })
      .addCase(getStores.pending, (state) => {
        state.loading = true
      })
      .addCase(getStores.rejected, (state) => {
        state.loading = false
      })
      .addCase(getStores.fulfilled, (state, action) => {
        state.stores = action.payload
        state.loading = false
      })
      .addCase(delStore.pending, (state) => {
        state.loading = true
      })
      .addCase(delStore.rejected, (state) => {
        state.loading = false
      })
      .addCase(delStore.fulfilled, (state, action) => {
        state.stores = action.payload.data
        state.loading = false
      })

      .addCase(saveStore.fulfilled, (state, action) => {
        state.stores = action.payload.stores.data
        state.loading = false
      })
    // .addCase(loadCountries.rejected, (state, action) => {})
    // .addCase(loadCountries.fulfilled, (state, action) => {
    //   state.countries = action.payload
    //   state.loadingCountries = false
    // })
  }
})

export const { setSelectedStore, clearSelectedStore } = storeStore.actions

export default storeStore.reducer
