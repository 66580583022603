import request from '../../shared/lib/request'
const rootApiUrl = process.env.REACT_APP_API_URL

function search(val) {
  return request({
    url: `${rootApiUrl}/bundle/${val}/search`,
    method: 'GET'
  })
}

function get() {
  return request({
    url: `${rootApiUrl}/bundle`,
    method: 'GET'
  })
}

function getById(id) {
  return request({
    url: `${rootApiUrl}/bundle/${id}`,
    method: 'GET'
  })
}

function post(bundle) {
  return request({
    url: `${rootApiUrl}/bundle`,
    method: 'POST',
    data: bundle
  })
}

function del(id) {
  return request({
    url: `${rootApiUrl}/bundle/${id}`,
    method: 'DELETE'
  })
}

const BundlesService = {
  search,
  get,
  getById,
  post,
  del
}

export default BundlesService
