// ** Redux, Thunk & Root Reducer Imports

import { applyMiddleware, compose, createStore } from 'redux'

import createDebounce from 'redux-debounced'
import rootReducer from '../reducers/rootReducer'
import thunk from 'redux-thunk'

// ** Async Dispatch Middleware
const asyncDispatchMiddleware = (storeAPI) => (next) => (action) => {
  let syncActivityFinished = false
  let actionQueue = []

  function flushQueue() {
    actionQueue.forEach((a) => storeAPI.dispatch(a))
    actionQueue = []
  }

  const asyncDispatch = (asyncAction) => {
    actionQueue = actionQueue.concat([asyncAction])

    if (syncActivityFinished) {
      flushQueue()
    }
  }

  const actionWithAsyncDispatch = Object.assign({}, action, { asyncDispatch })

  const res = next(actionWithAsyncDispatch)
  syncActivityFinished = true
  flushQueue()

  return res
}

// ** init middleware
const middleware = [thunk, createDebounce(), asyncDispatchMiddleware]

// ** Dev Tools
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose

// ** Create store
const store = createStore(
  rootReducer,
  {},
  composeEnhancers(applyMiddleware(...middleware))
)

export { store }
