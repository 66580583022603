import { CardImg } from 'reactstrap'
import CarouselUncontrolled from '../pages/social/Visibility/CarouselUncontrolled'

function MediaTypeView(props) {
  let img
  let video
  let height
  let width
  let webp
  const data = props.data

  //debugger
  const coverImg = props.pnSLightMode ? data.coverImage : null

  // <div className="media-card-image">
  //   <CardImg
  //     top
  //     id={`selector_${data.pk}`}
  //     style={{ maxHeight: '85vh' }}
  //     className="img-fluid"
  //     src={coverImg ?? webp ?? img}
  //     height={height}
  //     width={width}
  //     alt="card image cap"
  //   />
  // </div>

  if (data.mediaType === 1) {
    webp = data.images[0].webpUri
    img = data.images[0].localUri
    height = data.images[0].height
    width = data.images[0].width

    return height / 360 > 4 ? (
      <div className="media-card-image">
        <CardImg
          top
          id={`selector_${data.pk}`}
          style={{ maxHeight: '85vh' }}
          className="img-fluid"
          src={coverImg ?? webp ?? img}
          height={height}
          width={width}
          alt="card image cap"
        />
      </div>
    ) : (
      <div className="media-card-image">
        <CardImg
          top
          id={`selector_${data.pk}`}
          style={{ maxHeight: '85vh' }}
          className="img-fluid"
          src={coverImg ?? webp ?? img}
          height={height}
          width={width}
          alt="card image cap"
        />
      </div>
    )
  }

  if (data.mediaType === 2) {
    video = data.videos[0].localUri
    return !coverImg ? (
      <div className="media-card-image">
        <video
          id={`selector_${data.pk}`}
          className="video-fluid"
          style={{ flex: 'auto', maxHeight: '85vh' }}
          src={video}
          controls
          muted
          //autoPlay="autoplay"
          loop
        ></video>
      </div>
    ) : (
      <div
        className={
          data.source === 'youtube' ? 'media-card-image-yt' : 'media-card-image'
        }
      >
        <CardImg
          top
          id={`selector_${data.pk}`}
          style={{ maxHeight: '85vh' }}
          className="img-fluid"
          src={coverImg ?? webp ?? img}
          height={height}
          width={width}
          alt="card image cap"
        />
      </div>
    )
  }

  if (data.mediaType === 8) {
    const imagesArray = []
    data.carousel.forEach((item, index) => {
      const sliderData = {}

      if (item.images.length > 0) {
        if (item.images.length >= 1) {
          const slidImg = item.images[0].webpUri ?? item.images[0].localUri
          sliderData.src = slidImg
        }
        if (item.mediaType === 2) {
          sliderData.src = item.videos[0].localUri
        }
        sliderData.caption = ''
        sliderData.header = ''
        sliderData.id = item.pk
        sliderData.key = item.pk
        sliderData.mediaType = item.mediaType

        imagesArray.push(sliderData)
      }
    })
    return !coverImg ? (
      <CarouselUncontrolled
        interval="1500"
        data={imagesArray}
        popinLayout={data.popinLayout}
      />
    ) : (
      <div className="media-card-image">
        <CardImg
          top
          id={`selector_${data.pk}`}
          style={{ maxHeight: '85vh' }}
          className="img-fluid"
          src={coverImg ?? webp ?? img}
          height={height}
          width={width}
          alt="card image cap"
        />
      </div>
    )
  }
}

export default MediaTypeView
